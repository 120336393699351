import React from "react"
import { graphql, Link } from "gatsby";
import {Layout, SEO, Grid, GridCol} from "../components";
import Img from "gatsby-image";

class BlogIndexPage extends React.Component {
  render() {
    const posts = this.props.data.allMdx.edges;
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()

    return (
      <Layout>
        <SEO title="Blog"/>
        <Grid>
          {posts.map(item => (
            <GridCol className={'col-12 blog-item sm-spacing-bottom'}>
              <Grid>
                <GridCol className={'col-4 col-sm-12'}>
                  <Link to={item.node.fields.slug}>
                    {item.node.frontmatter.images &&
                    <Img className={'spacing-bottom sm-spacing-bottom--sm'}
                         fluid={item.node.frontmatter.images[0].childImageSharp.fluid}/>}
                  </Link>
                </GridCol>
                <GridCol className={'col-8 col-sm-12'}>
                  <div>
                    <span
                      className={'blog-item--date pr-2'}>{item.node.frontmatter.date}</span>
                    <Link
                      to={item.node.fields.slug}>{item.node.frontmatter.title}</Link>
                  </div>
                  <p>{item.node.excerpt}</p>
                </GridCol>
              </Grid>
            </GridCol>
          ))}
          <GridCol className={'col-12 ta-c'}>
            {!isFirst && (
              <Link to={`/blog/${prevPage}`} rel="prev" className={'pr-2'}>
                ← Previous Page
              </Link>
            )}
            {!isLast && (
              <Link to={`/blog/${nextPage}`} rel="next">
                Next Page →
              </Link>
            )}
          </GridCol>
        </Grid>
      </Layout>
    )
  }
}

export default BlogIndexPage

export const pageQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMdx(
      filter: {fileAbsolutePath: {regex: "/blog/"}},
      sort: { 
        fields: [frontmatter___date], 
        order: DESC 
      }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            images {
             childImageSharp {
               fluid(maxWidth: 290, quality: 100) {
                  ...GatsbyImageSharpFluid
               }
             }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`